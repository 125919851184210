<template>
  <div class="create-ad">
    <!-- 页头 -->
    <div class="page-header">
      <el-row>
        <el-col>
          <el-breadcrumb separator="/">
            <el-breadcrumb-item>首页</el-breadcrumb-item>
            <el-breadcrumb-item>推广码管理</el-breadcrumb-item>
            <el-breadcrumb-item>编辑/添加</el-breadcrumb-item>
          </el-breadcrumb>
        </el-col>
      </el-row>
    </div>
    <!-- 主要内容 -->
    <div class="main">
      <el-form class="form-container" ref="form" :model="form" label-width="140px">
         
        <el-form-item label="推广人姓名" :error="$v.form.name.$error ? '请输入推广人姓名' : ''">
          <el-input v-model="form.name"></el-input>
        </el-form-item>
        <el-form-item label="推广会员Id" :error="$v.form.pushid.$error ? '请输入推广会员Id' : ''">
          <el-input v-model="form.pushid"></el-input>
        </el-form-item>
        <el-form-item label="海报小程序路径" :error="$v.form.urlpath.$error ? '请输入海报小程序路径' : ''">
          <el-input v-model="form.urlpath"></el-input>
      
        </el-form-item>
        <el-form-item label="备注"  >
             <el-input v-model="form.remarks"></el-input>
        </el-form-item>
             <el-form-item label="小程序页面路径说明">

          <el-card class="box-card">
            <div class="text item"> 分类：pages/goods/classify?id= </div>
            <div class="text item"> 商品详情：pages/goods/detail?id= </div>       
            <div class="text item"> 我的 pages/my/index </div>
            <div class="text item"> 积分兑换：pages/my/points-mall</div>
            <div class="text item"> 我的优惠券：pages/my/coupon </div>
                     <div class="text item"> 员工推广商品列表 pages/goods/staffgoodslist </div>
          </el-card>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="createAd">保存</el-button>
          <el-button @click="back">返回</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import { editRequest, getdatilRequest } from "@/api/wx_userpushcode";
import ImageUpload from "@/components/imageUpload";
import { required } from "vuelidate/lib/validators";
import utils from "@/utils/util";
export default {
  typeName: "Articletypecreate",
  data() {
    return {
      classify: [],
      form: {
        id: 0,
        name: "",

        addtime: ""
      }
    };
  },
  components: {
    "image-upload": ImageUpload
  },
  created() {
    this.form.id = this.$route.query["id"];

    this.getdatil();
  },
  methods: {
    back() {
      this.$router.back();
    },
    getdatil() {
      getdatilRequest({
        Id: this.form.id
      }).then(res => {
        this.form = res.data;
      });
    },
   imageUpload(url) {
      this.form.homeimg = url
    },
    createAd() {
      this.$v.form.$touch();
      if (!this.$v.form.$invalid) {
        let params = JSON.parse(JSON.stringify(this.form));

        editRequest(params).then(res => {
          if (res.code == 1) {
            this.$router.go(-1);
          } else {
            this.$message.error(res.msg);
          }
        });
      }
    }
  },
  validations: {
    form: {
      name: {
        required
      },
     
      pushid: {
        required
      },
      urlpath: {
        required
      } 
    }
  }
};
</script>

<style lang="less" scoped>
/deep/ .el-cascader .el-input input {
  width: 460px;
}
</style>
